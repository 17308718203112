import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { htmlParse } from '../../../utils/htmlParse'
import SizeChartModal from '../SizeChartModal/SizeChartModal'
import { StoreContext } from '../../../context/store-context'
import Toast from '../Toast/Toast'
import { Link } from 'gatsby'
import './ShopProduct.css'
import { IProductProps } from '../../../templates/ShopifyProduct/ShopifyProduct'
import { formatPrice } from '../../../utils/formatPrice'

export interface IMedia {
  id: string
  image: { gatsbyImageData: IGatsbyImageData }
}

const ShopProduct = (props: IProductProps) => {
  const { title, priceRangeV2, options, variants, media, descriptionHtml } =
    props

  const { addVariantToCart, checkout, loading } = React.useContext(StoreContext)

  // Variants of the product that are available for purchase
  const availableVariants = variants
    .filter((variant) => variant.availableForSale)
    .map((variant) => variant.title)

  // Colour options available for the product
  const colorOptions = options.filter((option) => option.name === 'Color')[0]
    .values

  // Selected colour option
  const [selectedColor, setSelectedColor] = useState<string>(
    colorOptions.filter((option) =>
      availableVariants.some(
        (variant) =>
          variant.split('/')[0].trimEnd() === option ||
          variant.split('/')[1].trimStart() === option
      )
    )[0]
  )
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(
    colorOptions.indexOf(selectedColor)
  )

  // Size options available for the product
  const sizeOptions = options.filter((option) => option.name === 'Size')[0]
    .values

  const getSelectedSize = () =>
    sizeOptions.filter((option) =>
      availableVariants.some(
        (variant) =>
          (variant.split('/')[1].trimStart() === option &&
            variant.split('/')[0].trimEnd() === selectedColor) ||
          (variant.split('/')[0].trimEnd() === option &&
            variant.split('/')[1].trimStart() === selectedColor)
      )
    )[0]
  // Selected size option
  const [selectedSize, setSelectedSize] = useState<string>(getSelectedSize())

  useEffect(() => {
    if (isVariantDisabled(selectedColor, 'color'))
      setSelectedSize(getSelectedSize())
  }, [selectedColor])

  const getSelectedVariant = (variant: { title: string }) => {
    return (
      variant.title === `${selectedColor} / ${selectedSize}` ||
      variant.title === `${selectedSize} / ${selectedColor}`
    )
  }

  // For the selected product variant, how many are in inventory?
  const [availableInventory, setAvailableInventory] = useState<number>(
    variants.find((variant) => getSelectedVariant(variant))
      ?.inventoryQuantity || 0
  )

  // The quantity selected in the dropdown
  const [selectedQuantity, setSelectedQuantity] = useState<{
    value: number
    label: string
  }>({ value: 1, label: '1' })

  // The quantity options for each variant in the quantity dropdown
  const [dropdownOptions, setDropdownOptions] = useState<
    { value: number; label: string }[]
  >([])

  // The ID of the selected product variant
  const [selectedVariantId, setSelectedVariantId] = useState<string>('')

  useEffect(() => {
    // Initial quantity of variant available in the quantity dropdown
    const initialQuantity =
      variants.filter((variant) => getSelectedVariant(variant))[0]
        .inventoryQuantity || 0
    // Quantity of variant in the cart
    const cartQuantity = checkout.lines.nodes.filter(
      (lineItem) => lineItem.merchandise?.id === selectedVariantId
    )[0]?.quantity
    // When variant is added to the cart, reduce the quantity available in the quantity dropdown
    const updatedQuantity = initialQuantity - (cartQuantity ? cartQuantity : 0)
    setAvailableInventory(updatedQuantity)
    setSelectedQuantity({ value: 1, label: '1' })
    setSelectedVariantId(
      variants.filter((variant) => getSelectedVariant(variant))[0].storefrontId
    )
  }, [selectedColor, selectedSize, checkout])

  useEffect(() => {
    // Update the quantity dropdown options according to how many of each product variant is available
    setDropdownOptions(
      [...Array(availableInventory + 1).keys()].slice(1).map((i) => {
        return { value: i, label: String(i) }
      })
    )
  }, [availableInventory])

  // Disable the variant buttons of a particular variant if the selected colour/size combination is not in stock
  const isVariantDisabled = (option: string, type: 'color' | 'size') => {
    if (
      type === 'color' &&
      !availableVariants.includes(`${option} / ${selectedSize}`) &&
      !availableVariants.includes(`${selectedSize} / ${option}`)
    ) {
      return true
    } else if (
      type === 'size' &&
      !availableVariants.includes(`${selectedColor} / ${option}`) &&
      !availableVariants.includes(`${option} / ${selectedColor}`)
    ) {
      return true
    } else return false
  }

  // Determines the button colour for the size and colour variant buttons
  const getButtonColor = (option: string, type: 'color' | 'size') => {
    if (isVariantDisabled(option, type)) {
      return 'border-grey-400 text-grey-400 cursor-not-allowed'
    } else if (type === 'color') {
      return option === selectedColor
        ? 'bg-black text-white border-[#206165]'
        : 'border-black text-black'
    } else if (type === 'size') {
      return option === selectedSize
        ? 'bg-black text-white border-[#206165]'
        : 'border-black text-black'
    }
  }

  const addToCart = () => {
    addVariantToCart(selectedVariantId, selectedQuantity.label)
  }

  return (
    <div className="ShopProduct page-section">
      <div className="inner-container">
        <Toast show={loading}>
          <Link to="/shop/cart" role="alert">
            Added to cart!
          </Link>
        </Toast>
        <div className="flex flex-wrap md:flex-nowrap gap-1 md:gap-8 justify-center items-start">
          <div className="max-w-[300px] md:max-w-[500px]">
            <GatsbyImage
              alt={title}
              image={
                media.length < colorOptions.length
                  ? media[0].image.gatsbyImageData
                  : media[selectedImageIndex].image.gatsbyImageData
              }
            />
          </div>
          <ul className="max-w-[200px] sm:max-w-[80px] flex sm:block">
            {media.map((mediaItem, index) => (
              <li key={mediaItem.id}>
                <button
                  data-testid="image-variant-button"
                  aria-label={`View image ${index + 1}`}
                  onClick={() => {
                    const selectedColor = colorOptions[index]
                      ? colorOptions[index]
                      : colorOptions[0]
                    setSelectedColor(selectedColor)
                    setSelectedImageIndex(index)
                  }}
                  className={`transition hover:contrast-[90%] ${
                    index === selectedImageIndex ? 'contrast-[90%]' : ''
                  }`}
                  disabled={isVariantDisabled(
                    colorOptions[index] ? colorOptions[index] : colorOptions[0],
                    'color'
                  )}
                >
                  <GatsbyImage alt="" image={mediaItem.image.gatsbyImageData} />
                </button>
              </li>
            ))}
          </ul>
          <div>
            <h1 className="text-3xl font-normal mb-2">{title}</h1>
            <p className="text-[#206165] text-4xl mb-4">
              {formatPrice('CAD', priceRangeV2.maxVariantPrice.amount)}
            </p>
            <p className="mb-1">Color</p>
            {colorOptions.length > 1 ? (
              <ul className="flex gap-2 mb-6">
                {colorOptions.map((option, index) => (
                  <li key={option}>
                    <button
                      data-testid="color-option-button"
                      disabled={isVariantDisabled(option, 'color')}
                      onClick={() => {
                        setSelectedColor(option)
                        setSelectedImageIndex(index)
                      }}
                      className={`border-2 p-1 text-sm focus-visible:bg-black focus-visible:text-white ${getButtonColor(
                        option,
                        'color'
                      )}`}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <span
                data-testid="color-option-button"
                className="border-2 border-[#206165] text-[#206165] p-1 text-sm mb-6"
              >
                {colorOptions[0]}
              </span>
            )}
            <p className="mb-1 mt-4">Size</p>
            {sizeOptions.length > 1 ? (
              <ul className="flex gap-2 mb-8">
                {sizeOptions.map((option) => (
                  <li key={option}>
                    <button
                      data-testid="size-option-button"
                      disabled={isVariantDisabled(option, 'size')}
                      onClick={() => setSelectedSize(option)}
                      className={`border-2 p-1 text-sm focus-visible:bg-black focus-visible:text-white ${getButtonColor(
                        option,
                        'size'
                      )}`}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <span
                data-testid="size-option-button"
                className="border-2 border-[#206165] text-[#206165] p-1 text-sm mb-8"
              >
                {sizeOptions[0]}
              </span>
            )}
            <div className="mb-8">
              <label
                className="block mb-1"
                htmlFor="quantity-dropdown"
                id="quantity-label"
              >
                Quantity
              </label>
              <ReactSelect
                id="quantity-dropdown"
                aria-labelledby="quantity-label"
                placeholder="Quantity"
                value={selectedQuantity}
                options={dropdownOptions}
                onChange={(e) =>
                  setSelectedQuantity({
                    value: e?.value || 1,
                    label: e?.label || '1',
                  })
                }
              />
            </div>
            <div>
              <button
                className="btn btn-1 btn-sm"
                onClick={addToCart}
                disabled={
                  !selectedColor || !selectedSize || !availableVariants.length
                }
              >
                Add to cart
              </button>
            </div>
            <div className="DescriptionContainer">
              {htmlParse(descriptionHtml)}
            </div>
            <div className="mt-8 mb-24">
              <SizeChartModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopProduct
